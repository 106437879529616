import React from 'react'
import './404.css'

const Error404 = () => (
  <div className="error-container">
    <div>
      Not found
    </div>
    <a href="./" style={{ marginTop: '4rem' }}>Go to main page</a>
  </div>
)

export default Error404
